import React, {useEffect, useState} from 'react';
import './App.css';
import {basePath} from './options';


function Clock(props){

    let [html, setHtml] = useState("");
    let [flag, setFlag] = useState(false);
    let [clocks, setClocks] = useState([]);

    let [low, setLow] = useState(0);
    let [current, setCurrent] = useState(0);

    const initialIgnore = 5;

    useEffect(()=>{
        fetch(props.link)
            .then(response => response.text())
            .then(html => {setHtml(html); setFlag(true);});
    }, [props.link]);

    useEffect(()=>{
        if(html !== "" ){
            let table = html.split("table")[1];
            if(table !== undefined){
                let rows = table.split("tr>");
                if(rows !== undefined){
                    let arr = [];
                    let first=true;
                    let smallest;
                    for(let i = 3; i<rows.length; i++){
                        let volt = rows[i].split("</span>")[0];
                        let temp = volt.split(">");
                        volt = temp[temp.length-1];
                        
                        volt = Number(volt);
                        if(first){

                            setCurrent(volt.toFixed(2));
                            setLow(volt);
                            first = false;
                            smallest = volt;
                        }
                        if((rows.length-i)>(initialIgnore*2)){
                            if(volt < smallest){
                                smallest = volt;
                            }
                        }
                        i++;
                    }
                    setLow(smallest.toFixed(2));
                    setClocks(arr);

                    if(props.last){
                        props.setDone(true);
                    }
                }
            }
        }
    }, [flag]);

    let display;
    if(!flag){
        if(props.moreInfo){
            display = 
            <tr>
                <td></td>
                <td></td>
                <td>Loading....</td>
                <td></td>
                <td></td>
            </tr>;
        }else{
            display = 
            <tr>
                <td></td>
                <td>Loading....</td>
            </tr>;
        }
    }else{
        if(props.useMin){
            if(low < props.min){
                if(props.moreInfo){
                    display = <tr>
                        <td><a href={props.link} target="_blank">{props.name}</a></td>
                        <td>{props.date}</td>
                        <td>{current}</td>
                        <td>{low}</td>
                        <td>{props.room}</td>
                    </tr>;
                }else{
                    display = <tr>
                        <td><a href={props.link} target="_blank">{props.name}</a></td>
                        <td>{props.room}</td>
                    </tr>;
                }
                
            }else{
                display=<span/>;
            }
        }else{
            if(props.moreInfo){
                display = <tr>
                    <td><a href={props.link} target="_blank">{props.name}</a></td>
                    <td>{props.date}</td>
                    <td>{current}</td>
                    <td>{low}</td>
                    <td>{props.room}</td>
                </tr>;
            }else{
                display = <tr>
                    <td><a href={props.link} target="_blank">{props.name}</a></td>
                    <td>{props.room}</td>
                </tr>;
            }
        }
        
    }

    return (display
    );
}
export default Clock;